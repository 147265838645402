<template>
  <div id="components-layout-demo-basic">
    <a-layout>
      <a-layout-header>
        <Header />
      </a-layout-header>
      <a-layout-content>
        <div class="slider-img-box">
          <div class="content">
            <div class="imgclass">
              <img src="../assets/image/loginbg.png" alt="" />
            </div>
            <div :class="[show ? 'fbbox2' : 'fpbox']">
              <div class="formbox">
                <div class="titlep">
                  <p>{{ !show ? $t("findThePassword") : $t("reset") }}</p>
                </div>

                <a-tabs default-active-key="1" @change="changetab">
                  <!-- 邮箱 -->
                  <a-tab-pane key="1" :tab="$t('mailboxRetrievalPassword')">
                    <a-form
                      :form="form1"
                      class="login-form"
                      @submit="handleSubmit"
                      v-show="!show"
                    >
                      <a-form-item>
                        <a-input
                          autocomplete="off"
                          @keyup.enter="handleSubmit"
                          ref="email"
                          v-decorator="[
                            'email',
                            {
                              rules: [
                                {
                                  type: 'email',
                                  message: this.$t('emailtrue'),
                                },
                                {
                                  required: true,
                                  message: this.$t('emailRule'),
                                },
                                {
                                  pattern: /^[^\s]*$/,
                                  message: this.$t('notEmpty'),
                                },
                              ],
                            },
                          ]"
                          :placeholder="this.$t('placeholderRegistMail')"
                          style="line-height: 50px; width: 80%"
                        >
                          <a-icon
                            slot="prefix"
                            type="mail"
                            style="color: rgba(0, 0, 0, 0.25)"
                          />
                        </a-input>
                      </a-form-item>
                      <!-- 验证码 -->
                      <a-form-item>
                        <div class="codeinput">
                          <a-input
                            autocomplete="off"
                            v-decorator="[
                              'code',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: this.$t('codeRule'),
                                  },
                                  {
                                    len: 4,
                                    message: this.$t('codeLenRule'),
                                  },
                                  {
                                    pattern: /^[^\s]*$/,
                                    message: this.$t('notEmpty'),
                                  },
                                ],
                              },
                            ]"
                            :placeholder="this.$t('placeholderRegistCode')"
                            style="width: 72%"
                          >
                            <a-icon
                              slot="prefix"
                              type="safety-certificate"
                              style="color: rgba(0, 0, 0, 0.25)"
                            />
                          </a-input>
                          <span class="codeimg" @click="getCode()">
                            <img :src="codesrc" class="verCode" alt="" />
                          </span>
                        </div>
                      </a-form-item>

                      <a-form-item>
                        <a-button
                          type="primary"
                          html-type="submit"
                          class="login-form-button"
                        >
                          {{ $t(`nextStep`) }}
                        </a-button>
                      </a-form-item>
                    </a-form>
                    <!-- 邮箱再次输入密码框 -->
                    <a-form
                      v-show="show"
                      :form="form2"
                      class="login-form2"
                      @submit="handleSubmitPwd"
                    >
                      <a-form-item>
                        <a-input
                          autocomplete="off"
                          @keyup.enter="handleSubmitPwd"
                          style="line-height: 50px; width: 80%"
                          v-decorator="[
                            'userName',
                            {
                              initialValue: userName,
                            },
                          ]"
                          disabled
                          type="userName"
                        >
                          <a-icon
                            slot="prefix"
                            type="user"
                            style="color: rgba(0, 0, 0, 0.25)"
                          />
                        </a-input>
                      </a-form-item>
                      <a-form-item>
                        <a-input
                          autocomplete="off"
                          style="line-height: 50px; width: 80%"
                          disabled
                          v-decorator="[
                            'email',
                            {
                              initialValue: mail,
                            },
                          ]"
                          type="email"
                        >
                          <a-icon
                            slot="prefix"
                            type="mail"
                            style="color: rgba(0, 0, 0, 0.25)"
                          />
                        </a-input>
                      </a-form-item>
                      <a-form-item>
                        <a-tooltip placement="right">
                          <template slot="title"
                            ><span class="tips">{{ $t(`codeTip`) }}</span>
                          </template>
                          <a-input
                            autocomplete="off"
                            v-decorator="[
                              'code',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: this.$t('codeRule'),
                                  },
                                  {
                                    pattern: /^[^\s]*$/,
                                    message: this.$t('notEmpty'),
                                  },
                                ],
                              },
                            ]"
                            :placeholder="this.$t('placeholderRegistCode')"
                            style="line-height: 50px; width: 80%"
                          >
                            <a-icon
                              slot="prefix"
                              type="safety-certificate"
                              style="color: rgba(0, 0, 0, 0.25)"
                            />
                          </a-input>
                        </a-tooltip>
                      </a-form-item>
                      <a-form-item>
                        <a-tooltip placement="right">
                          <template slot="title"
                            ><span class="tips">{{ $t(`passwordTip`) }}</span>
                          </template>
                          <a-input-password
                            autocomplete="off"
                            v-decorator="[
                              'password',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: this.$t('passwordRule'),
                                  },
                                  {
                                    validator: validateToNextPassword,
                                  },
                                  {
                                    pattern: /^[^\s]*$/,
                                    message: this.$t('notEmpty'),
                                  },

                                  {
                                    pattern:
                                      /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![\u4e00-\u9fa5a]+$)(?![-_]+$)[\u4e00-\u9fa5a-zA-Z0-9_-]{8,32}$/,
                                    message: this.$t('userPwdRule'),
                                  },
                                ],
                              },
                            ]"
                            type="password"
                            :placeholder="this.$t('placeholderRegistPwd')"
                            style="line-height: 50px; width: 80%"
                          >
                            <a-icon
                              slot="prefix"
                              type="lock"
                              style="color: rgba(0, 0, 0, 0.25)"
                            />
                          </a-input-password>
                        </a-tooltip>
                      </a-form-item>
                      <!-- 确认密码 -->
                      <a-form-item>
                        <a-tooltip placement="right">
                          <template slot="title"
                            ><span class="tips">{{ $t(`confirmTip`) }}</span>
                          </template>
                          <a-input-password
                            autocomplete="off"
                            v-decorator="[
                              'confirm',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: this.$t('confirmPasswordRule'),
                                  },
                                  {
                                    validator: compareToFirstPassword,
                                  },
                                  {
                                    pattern: /^[^\s]*$/,
                                    message: this.$t('notEmpty'),
                                  },
                                ],
                              },
                            ]"
                            type="password"
                            :placeholder="this.$t('placeholderRegistPwd2')"
                            @blur="handleConfirmBlur"
                            style="line-height: 50px; width: 80%"
                          >
                            <a-icon
                              slot="prefix"
                              type="lock"
                              style="color: rgba(0, 0, 0, 0.25)"
                            />
                          </a-input-password>
                        </a-tooltip>
                      </a-form-item>
                      <a-form-item>
                        <a-button
                          type="primary"
                          html-type="submit"
                          class="login-form-button"
                        >
                          {{ $t(`ok`) }}
                        </a-button>
                      </a-form-item>
                    </a-form>
                  </a-tab-pane>

                  <a-tab-pane key="2" :tab="$t('phoneRetrievePassword')">
                    <!-- 手机 -->
                    <a-form
                      :form="form3"
                      class="login-form"
                      @submit="handleSubmit3"
                      v-show="!show3"
                    >
                      <a-form-item>
                        <a-tooltip placement="right">
                          <template slot="title">
                            <span class="tips">{{ $t(`phoneTip`) }}</span>
                          </template>
                          <a-input-group
                            compact
                            style="line-height: 40px; width: 80%"
                          >
                            <a-select
                              :default-value="beare"
                              style="
                                line-height: 45px;
                                width: 25%;
                                height: 45px;
                                margin-top: 2px;
                              "
                              @change="handleChange"
                            >
                              <a-select-option
                                :value="item.id"
                                v-for="(item, index) in areaCode"
                                :key="index"
                              >
                                {{ item.head }}
                              </a-select-option>
                            </a-select>
                            <a-input
                              autocomplete="off"
                              @keyup.enter="handleSubmit"
                              ref="phone"
                              v-decorator="[
                                'phone',
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: this.$t('phoneRule'),
                                    },
                                    {
                                      pattern: regRule,
                                      message: this.$t('phonetrue'),
                                    },
                                    {
                                      pattern: /^[^\s]*$/,
                                      message: this.$t('notEmpty'),
                                    },
                                  ],
                                },
                              ]"
                              :placeholder="this.$t('placeholderRegistPhone')"
                              style="line-height: 50px; width: 80%"
                            >
                              <a-icon
                                slot="prefix"
                                type="phone"
                                style="color: rgba(0, 0, 0, 0.25)"
                              />
                            </a-input>
                          </a-input-group>
                        </a-tooltip>
                      </a-form-item>

                      <!-- 验证码 -->
                      <a-form-item>
                        <div class="codeinput">
                          <a-input
                            autocomplete="off"
                            v-decorator="[
                              'code',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: this.$t('codeRule'),
                                  },
                                  {
                                    len: 4,
                                    message: this.$t('codeLenRule'),
                                  },
                                  {
                                    pattern: /^[^\s]*$/,
                                    message: this.$t('notEmpty'),
                                  },
                                ],
                              },
                            ]"
                            :placeholder="this.$t('placeholderRegistCode')"
                            style="width: 72%"
                          >
                            <a-icon
                              slot="prefix"
                              type="safety-certificate"
                              style="color: rgba(0, 0, 0, 0.25)"
                            />
                          </a-input>
                          <span class="codeimg" @click="getCode()">
                            <img :src="codesrc" class="verCode" alt="" />
                          </span>
                        </div>
                      </a-form-item>
                      <a-form-item>
                        <a-button
                          type="primary"
                          html-type="submit"
                          class="login-form-button"
                        >
                          {{ $t(`nextStep`) }}
                        </a-button>
                      </a-form-item>
                    </a-form>
                    <!-- 手机号再次输入密码 -->
                    <a-form
                      v-show="show3"
                      :form="form4"
                      class="login-form4"
                      @submit="handleSubmitPwd3"
                    >
                      <a-form-item>
                        <!-- 用户名 -->
                        <a-input
                          autocomplete="off"
                          @keyup.enter="handleSubmitPwd3"
                          style="line-height: 50px; width: 80%"
                          v-decorator="[
                            'userName',
                            {
                              initialValue: userName,
                            },
                          ]"
                          disabled
                          type="userName"
                        >
                          <a-icon
                            slot="prefix"
                            type="user"
                            style="color: rgba(0, 0, 0, 0.25)"
                          />
                        </a-input>
                      </a-form-item>
                      <a-form-item>
                        <!-- 手机号 -->
                        <a-input
                          autocomplete="off"
                          style="line-height: 50px; width: 80%"
                          disabled
                          v-decorator="[
                            'phone',
                            {
                              initialValue: phone,
                            },
                          ]"
                          type="phone"
                        >
                          <a-icon
                            slot="prefix"
                            type="phone"
                            style="color: rgba(0, 0, 0, 0.25)"
                          />
                        </a-input>
                      </a-form-item>
                      <a-form-item>
                        <!-- 验证码 -->
                        <a-tooltip placement="right">
                          <template slot="title"
                            ><span class="tips">{{ $t(`phoneCodeTip`) }}</span>
                          </template>
                          <a-input
                            autocomplete="off"
                            v-decorator="[
                              'code',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: this.$t('codeRule'),
                                  },
                                  {
                                    pattern: /^[^\s]*$/,
                                    message: this.$t('notEmpty'),
                                  },
                                ],
                              },
                            ]"
                            :placeholder="this.$t('placeholderRegistCode')"
                            style="line-height: 50px; width: 80%"
                          >
                            <a-icon
                              slot="prefix"
                              type="safety-certificate"
                              style="color: rgba(0, 0, 0, 0.25)"
                            />
                          </a-input>
                        </a-tooltip>
                      </a-form-item>
                      <a-form-item>
                        <!-- 密码 -->
                        <a-tooltip placement="right">
                          <template slot="title"
                            ><span class="tips">{{ $t(`passwordTip`) }}</span>
                          </template>
                          <a-input-password
                            autocomplete="off"
                            v-decorator="[
                              'password',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: this.$t('passwordRule'),
                                  },
                                  {
                                    validator: validateToNextPassword1,
                                  },
                                  {
                                    pattern: /^[^\s]*$/,
                                    message: this.$t('notEmpty'),
                                  },
                                  {
                                    pattern:
                                      /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![\u4e00-\u9fa5a]+$)(?![-_]+$)[\u4e00-\u9fa5a-zA-Z0-9_-]{8,32}$/,
                                    message: this.$t('userPwdRule'),
                                  },
                                ],
                              },
                            ]"
                            type="password"
                            :placeholder="this.$t('placeholderRegistPwd')"
                            style="line-height: 50px; width: 80%"
                          >
                            <a-icon
                              slot="prefix"
                              type="lock"
                              style="color: rgba(0, 0, 0, 0.25)"
                            />
                          </a-input-password>
                        </a-tooltip>
                      </a-form-item>
                      <!-- 确认密码 -->
                      <a-form-item>
                        <a-tooltip placement="right">
                          <template slot="title"
                            ><span class="tips">{{ $t(`confirmTip`) }}</span>
                          </template>
                          <a-input-password
                            autocomplete="off"
                            v-decorator="[
                              'confirm',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: this.$t('confirmPasswordRule'),
                                  },
                                  {
                                    validator: compareToFirstPassword1,
                                  },
                                  {
                                    pattern: /^[^\s]*$/,
                                    message: this.$t('notEmpty'),
                                  },
                                ],
                              },
                            ]"
                            type="password"
                            :placeholder="this.$t('placeholderRegistPwd2')"
                            @blur="handleConfirmBlur"
                            style="line-height: 50px; width: 80%"
                          >
                            <a-icon
                              slot="prefix"
                              type="lock"
                              style="color: rgba(0, 0, 0, 0.25)"
                            />
                          </a-input-password>
                        </a-tooltip>
                      </a-form-item>
                      <a-form-item>
                        <a-button
                          type="primary"
                          html-type="submit"
                          class="login-form-button"
                        >
                          {{ $t(`ok`) }}
                        </a-button>
                      </a-form-item>
                    </a-form>
                  </a-tab-pane>
                </a-tabs>
              </div>
              <div class="bottomdiv">
                <div :class="[show ? 'bug' : '', 'exit']">
                  <span @click="exit">{{ $t(`backLogin`) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-layout-content>

      <a-layout-footer>
        <Footer />
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import { showmessage } from "../utils/showMessage";
import { selectlan } from "../utils/selectLanguage";
import { encrypt } from "@/utils/aes";
import Header from "./modules/header.vue";
import Footer from "./modules/footer.vue";

export default {
  components: { Header, Footer },
  data() {
    return {
      show3: false,
      areaCode: [],
      regRule: "",
      beare: "+86",
      show: false,
      userName: "",
      visible: false,
      mail: "",
      phone: "",
      userId: "",
      checkCode: "",
      codesrc: "",
      token: "",
    };
  },
  created() {
    this.getCode();
  },
  beforeCreate() {
    this.form1 = this.$form.createForm(this, { name: "Fpassword" });
    this.form2 = this.$form.createForm(this, { name: "Cpassword" });
    // 手机找回密码
    this.form3 = this.$form.createForm(this, { name: "FPpassword" });
    this.form4 = this.$form.createForm(this, { name: "CPpassword" });
  },

  computed: {
    changeLan: function () {
      // console.log(this.$store.state.changeLan);
      return this.$store.state.changeLan;
    },
  },

  watch: {
    changeLan() {
      this.form1.resetFields();
      this.form2.resetFields();
      this.form3.resetFields();
      this.form4.resetFields();
    },
  },
  mounted() {
    let that = this;
    that.$http.post("/transfer/phoneSupport/v1", {}).then((res) => {
      // 暂时先写只有中国的号码 希望是在切换完语言后只展示本国的 还是所有的都展示
      if (res) {
        if (res.code != undefined && res.code != null && res.code == 2000) {
          console.log(res.data, "res.data");
          that.areaCode = res.data; //多地区区号显示
          that.regRule = that.areaCode[0].rule;
          that.beare = that.areaCode[0].head;
          if (res.code == 4012) {
            this.$message.destroy();
            this.$message.error(this.$t("hadUser"));
          }
        } else {
          this.$message.destroy();
          this.$message.error(this.$t("" + showmessage(res)));
        }
      } else {
        this.$message.destroy();
        this.$message.error(this.$t("getareacodefail"));
      }
    });
  },
  methods: {
    changetab() {
      this.form1.resetFields();
      this.form3.resetFields();
    },
    // 切换区号修改电话号码的正则
    handleChange(value) {
      this.areaCode.forEach((item) => {
        if (value == item.id) {
          this.regRule = item.rule;
          console.log(item, item.rule, "item.rule");
        }
      });
    },
    exit() {
      this.$router.push({ path: "/" });
    },
    //获取验证码
    getCode() {
      this.$http.post("/transfer/image/v1/").then((res) => {
        if (res) {
          if (res.code != undefined && res.code != null && res.code == 200) {
            this.codesrc = "data:image/jpg;base64," + res.data.image;
            this.token = res.data.codeToken;
          } else {
            this.$message.destroy();
            this.$message.error(this.$t("" + showmessage(res)));
          }
        } else {
          this.$message.destroy();
          this.$message.error(this.$t("getcodefail"));
        }
      });
    },
    // 输入邮箱验证邮箱是否存在
    handleSubmit(e) {
      e.preventDefault();
      this.form1.validateFields((err, values) => {
        if (!err) {
          this.$http
            .post(
              "/transfer/fpCodeVerify/v1",
              {
                verCode: values.code,
              },
              {
                headers: {
                  "Code-Token": this.token,
                },
              }
            )
            .then((res) => {
              if (res.code == 200) {
                this.$http
                  .post("/transfer/scmail/v1", {
                    mail: encrypt(values.email),
                    language: selectlan(),
                    type: "fp",
                  })
                  .then((res) => {
                    if (res.code == 2000) {
                      this.userId = res.data.userId;
                      this.userName = res.data.data;
                      this.mail = this.$refs.email.value;
                      this.show = true;
                      this.$message.destroy();
                      this.$message.success(this.$t("sendSuccess"));
                    } else if (res.code == 4024) {
                      // this.toolReset("mailNotFind");
                      this.toolReset(this.$t("" + showmessage(res)));
                    } else if (res.code == 4402) {
                      this.toolReset("mailSendMuch");
                    } else if (res.code == 4404) {
                      this.toolReset("mailFast");
                    } else {
                      this.toolReset(this.$t("" + showmessage(res)));
                    }
                  });
              } else if (res.code == -1) {
                this.$message.error(this.$t("codeError"));
                this.getCode();
                this.form1.resetFields(["code", ""]);
              } else if (res.code == -2) {
                this.$message.error(this.$t("codeTimeout"));
                this.getCode();
                this.form1.resetFields(["code", ""]);
              } else if (res.code == 4048) {
                this.$message.error(this.$t("codeErrorMuch"));
                this.getCode();
              } else {
                // this.$message.error(res.msg);
                this.toolReset(this.$t("" + showmessage(res)));
              }
            });
        }
      });
    },
    // 输入手机号验证是否存在
    handleSubmit3(e) {
      e.preventDefault();
      this.form3.validateFields((err, values) => {
        if (!err) {
          this.$http
            .post(
              "/transfer/fpCodeVerify/v1",
              {
                verCode: values.code,
              },
              {
                headers: {
                  "Code-Token": this.token,
                },
              }
            )
            .then((res) => {
              if (res.code == 200) {
                this.$http
                  .post("/transfer/scphone/v1", {
                    phone: encrypt(values.phone),
                    language: selectlan(),
                    type: "fp",
                  })
                  .then((res) => {
                    if (res.code == 2000) {
                      this.userId = res.data.userId;
                      this.userName = res.data.data;
                      this.phone = this.$refs.phone.value;
                      this.show3 = true;
                      this.$message.destroy();
                      this.$message.success(this.$t(showmessage(res)));
                    } else if (res.code == 4024) {
                      this.toolReset("phoneNotFind");
                    } else if (res.code == 4402) {
                      this.toolReset("phoneSendMuch");
                    } else if (res.code == 4404) {
                      this.toolReset("phoneFast");
                    } else {
                      this.toolReset(this.$t("" + showmessage(res)));
                    }
                  });
              } else if (res.code == -1) {
                this.$message.error(this.$t("codeError"));
                this.getCode();
                this.form3.resetFields(["code", ""]);
              } else if (res.code == -2) {
                this.$message.error(this.$t("codeTimeout"));
                this.getCode();
                this.form3.resetFields(["code", ""]);
              } else if (res.code == 4048) {
                this.$message.error(this.$t("codeErrorMuch"));
                this.getCode();
              } else {
                // this.$message.error(res.msg);
                this.toolReset(this.$t("" + showmessage(res)));
              }
            });
        }
      });
    },
    //接收错误，以及重置验证码
    toolReset(msg) {
      this.$message.destroy();
      this.$message.error(this.$t(msg));
      this.form1.resetFields(["code", ""]);
      this.getCode();
    },
    // 修改当前邮箱号的密码
    handleSubmitPwd(e) {
      e.preventDefault();
      this.form2.validateFields((err, values) => {
        if (!err) {
          const send = {
            id: encrypt(this.userId),
            mail: encrypt(this.mail),
            verCode: values.code,
            newPass: encrypt(values.password),
          };
          this.$http.post("/transfer/fpmail/v1", send).then((res) => {
            if (res) {
              if (
                res.code != undefined &&
                res.code != null &&
                res.code == 2000
              ) {
                this.$message.destroy();
                this.$message.success(this.$t("mailChangeSuccess"));
                this.$router.push({ path: "/" });
              } else if (
                res.code != undefined &&
                res.code != null &&
                res.code == 4010
              ) {
                this.$message.destroy();
                this.$message.error(this.$t("codeError"));
              } else if (
                res.code != undefined &&
                res.code != null &&
                res.code == 4027
              ) {
                this.$message.destroy();
                this.$message.error(this.$t("pwdError"));
              } else {
                this.$message.destroy();
                this.$message.error(this.$t("" + showmessage(res)));
              }
            } else {
              this.$message.destroy();
              this.$message.error(this.$t("getmailfail"));
            }
          });
        }
      });
    },
    // 修改当前手机号的密码
    handleSubmitPwd3(e) {
      e.preventDefault();
      this.form4.validateFields((err, values) => {
        if (!err) {
          const send = {
            id: encrypt(this.userId),
            phone: encrypt(this.phone),
            verCode: values.code,
            newPass: encrypt(values.password),
            confirmPass: encrypt(values.password),
          };
          this.$http.post("/transfer/fpphone/v1", send).then((res) => {
            if (res) {
              if (
                res.code != undefined &&
                res.code != null &&
                res.code == 2000
              ) {
                this.$message.destroy();
                this.$message.success(this.$t("mailChangeSuccess"));
                this.$router.push({ path: "/" });
              } else if (
                res.code != undefined &&
                res.code != null &&
                res.code == 4010
              ) {
                this.$message.destroy();
                this.$message.error(this.$t("codeError"));
              } else if (
                res.code != undefined &&
                res.code != null &&
                res.code == 4027
              ) {
                this.$message.destroy();
                this.$message.error(this.$t("pwdError"));
              } else {
                this.$message.destroy();
                this.$message.error(this.$t("" + showmessage(res)));
              }
            } else {
              this.$message.destroy();
              this.$message.error(this.$t("getmailfail"));
            }
          });
        }
      });
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form2;
      if (value && value !== form.getFieldValue("password")) {
        callback(this.$t(`confirmTip`));
      } else {
        callback();
      }
    },
    compareToFirstPassword1(rule, value, callback) {
      const form = this.form4;
      if (value && value !== form.getFieldValue("password")) {
        callback(this.$t(`confirmTip`));
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form2;
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    },
    validateToNextPassword1(rule, value, callback) {
      const form = this.form4;
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    },
  },
};
</script>

<style scoped>
/* 头部 */
#components-layout-demo-basic .ant-layout-header {
  width: 100%;
  min-height: 64px;
  padding: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.formbox >>> .ant-tabs-bar {
  border-bottom: none;
  display: flex;
  padding-left: 10%;
}
.formbox >>> .ant-tabs-tab {
  font-size: 14px;
  padding: 0;
  padding-top: 10px;
}
.ant-select-selection-selected-value {
  margin-left: 26px;
  float: none;
}
.verCode {
  width: 100px !important;
  height: 45px !important;
  border: 1px solid #409eff;
  border-radius: 4px;
  margin-bottom: 2px;
}
.titlep {
  margin-left: 50px;
  text-align: left;
  font-size: 30px;
  height: 40px;
  margin-top: 15px;
  color: #000;
  margin-bottom: 10px;
}
.font {
  text-align: center;
  margin: 10px 0;
}

.tips {
  display: block;
  font-size: 13px;
  max-width: 185px;
}
.font {
  text-align: center;
  margin: 10px 0;
}
.findpassword {
  float: left;
  color: #000;
}
.exit {
  margin: 0 auto;
  width: 100%;
  font-size: 15px;
  cursor: pointer;
  color: #1890ff;
}
.exit:hover {
  color: #0195f8;
}
/* .bug .ant-form-item {
  margin-bottom: 20px;
} */
.bug {
  margin: 0 auto;
  width: 100%;
  font-size: 15px;
  cursor: pointer;
  color: #1890ff;
}
.bug .ant-form-item {
  margin-bottom: 10px;
}

/* 内容 */
#components-layout-demo-basic .ant-layout-content {
  height: calc(100vh - 128px);
  text-align: center;
}
.backgroundimg {
  width: 100%;
  height: calc(100vh - 128px);
  overflow: hidden;
}
.slider-img-box {
  display: flex;
  background-color: #f8f8f8;
  width: 100vw;
  height: calc(100vh - 128px);
  justify-content: center;
  align-items: center;
}
/* .slider-img-box img {
  position: relative;
  width: 100vw;
  height: calc(100vh - 128px);
} */

.fpbox,
.fbbox2 {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  width: 27vw;
  min-width: 510px;
  background-color: white;
  /* border-radius: 5px;
  box-shadow: 0 0 5px 2px #7c7c7c; */
  position: relative;
}
.fpbox >>> .ant-form-explain {
  text-align: left;
  margin-left: 10%;
}
.login-form2 >>> .ant-form-explain {
  text-align: left;
  margin-left: 10%;
}

.login-form4 >>> .ant-form-explain {
  text-align: left;
  margin-left: 10%;
}
.fpbox >>> .login-form4 .ant-form-item {
  margin-bottom: 7px;
}
.fpbox >>> .ant-input-affix-wrapper .ant-input,
.fbbox2 >>> .ant-input-affix-wrapper .ant-input {
  text-align: left !important;
}
.fpbox {
  height: 570px;
}
.fbbox2 {
  height: 570px;
}
.tip {
  display: inline-block;
  margin: 0 auto;
}
.inputpassword {
  padding: 15px 0;
  border-bottom: 2px solid #f5f5f5;
}
.inputpassword h2 {
  font-size: 20px;
  margin-bottom: -0.5em;
}
.formbox {
  /* position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%); */
  margin: 0 auto;
  width: 100%;
}
.formbox >>> .ant-input {
  font-size: 16px;
  height: 45px;
}
.formbox >>> .ant-form-item-children {
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
}
.login-form-button {
  width: 80%;
  height: 45px !important;
  font-size: 16px;
  margin-top: 2vh;
}
.codeinput {
  width: 80%;
  /* margin: 0 auto; */
  margin-top: 10px;
}
.codeinput >>> .ant-input-affix-wrapper {
  vertical-align: middle;
  float: left;
}
.slider-img-box >>> .ant-input-group {
  display: flex;
}
.slider-img-box >>> .ant-select-selection {
  height: 45px;
}
.slider-img-box >>> .ant-select-selection .ant-select-selection__rendered {
  height: 40px;
  line-height: 40px;
}
/* .slider-img-box >>> .ant-input-group-compact{
  display: flex;
} */
.codeimg {
  vertical-align: middle;
  float: right;
}
.login-form2 {
  margin-top: 0px;
}
.login-form2 .ant-form-item {
  width: 100%;
}
.login-form2 .login-form-button {
  width: 80%;
  height: 45px !important;
  font-size: 16px;
}

/* 底部 */
#components-layout-demo-basic .ant-layout-footer {
  background-color: #fff;
  padding: 0;
}
.imgclass {
  width: 26vw;
  min-width: 490px;
  height: 570px;
}
.imgclass img {
  width: 26vw;
  min-width: 490px;
  height: 570px;
}
.content {
  display: flex;
  min-width: 1000px;
  width: 53vw;
  height: 570px;
}
.bottomdiv {
  width: 80%;
  position: absolute;
  bottom: 20px;
  border-top: 1px solid #ddd;
  margin-left: 10%;
  padding-top: 20px;
}
.login-form2 .ant-form-item {
  margin-bottom: 7px;
}
.login-form4 .ant-form-item {
  margin-bottom: 7px;
}
</style>
